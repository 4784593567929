// external dependencies
import React from 'react';
import styled from 'styled-components';

// gatsby dependencies
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledBackgroundImage = styled(GatsbyImage)`
  width: 100%;
  min-width: 100%;
  height: auto;
  min-height: 300px;
  box-shadow: ${(props) => props.theme.effects.boxShadow};
  border-radius: ${(props) => props.theme.elements.borderRadius};
  position: relative;
  margin: 10px auto;

  h5 {
    position: absolute;
    bottom: 25px;
    left: 25px;
    color: ${(props) => props.theme.colors.white};
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.blue};
  opacity: 0.3;
  z-index: 1;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledTitleWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  left: 25px;
   h5 {
     color: white;
   }
`

// component definition
const ImageCard = ({ image, title, slug }) => {
  return (
    <Link to={slug}>
      <div style={{ display: 'grid', position: 'relative', height: 300 }}>
        <StyledOverlay />
        <GatsbyImage
          image={image && image.gatsbyImageData}
        />
        <StyledTitleWrapper>
          <h5>{title}</h5>
        </StyledTitleWrapper>
      </div>
    </Link>
  );
};

export default ImageCard;
