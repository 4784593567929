// externale dependencies
import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

// gatsby dependencies
import { graphql, useStaticQuery } from 'gatsby';

// local dependencies
import SEO from '../components/SEO';
import Hero from '../components/shared/Hero';
import Section from '../components/shared/Section';
import ImageCard from '../components/shared/ImageCard';
import Cta from '../components/shared/Cta';
import EnhancedGrid from '../components/shared/EnhancedGrid';

const StyledTextWrapper = styled.div`
  display: block;
  text-align: center;
  max-width: 650px;
  margin: auto;

  h3 {
    color: ${(props) => props.theme.colors.blue};
    margin-bottom: 2rem;
  }

  p {
    color: ${(props) => props.theme.colors.darkGray};
    margin: 1.5rem auto 2rem auto;
  }
`;

// component defintion
const WhoYouAreIndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      cards: allContentfulCustomerPageTemplate {
        nodes {
          slug
          pageTitle
          supertext
          heroImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
      page: contentfulWhoYouArePage {
        ctaSection {
          email
          headline
          phoneNumber
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        hero {
          buttonLink
          buttonText
          headline
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          title
        }
        metaDescription
        page
        textBlock {
          textBlockButtonLink
          textBlockSupertext
          textBlockHeadline
          textBlockButtonText
          textBlockBody {
            textBlockBody
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO description={data.page.metaDescription} title={data.page.page} />
      <Hero
        short
        bkgImage={data.page.hero.image}
        headline={data.page.hero.headline}
        buttonLink={data.page.hero.buttonLink}
        buttonText={data.page.hero.buttonText}
        supertext={data.page.hero.supertext}
      />
      <Section>
        <StyledTextWrapper>
          <h3>{data.page.textBlock.textBlockHeadline}</h3>
          <p>{data.page.textBlock.textBlockBody.textBlockBody}</p>
        </StyledTextWrapper>
      </Section>
      <Section style={{ paddingTop: 0 }}>
        <EnhancedGrid
          justifyContent='center'
          alignItems='flex-start'
          spacing={3}
        >
          {data.cards.nodes.map((card, i) => (
            <Grid item key={i} xs={12} sm={12} md={6}>
              <ImageCard
                slug={card.slug}
                // using supertext here as a quick fix
                // need to come back and add new field into
                // CMS to allow for a different page title
                // and meta title
                title={card.supertext}
                image={card.heroImage}
              />
            </Grid>
          ))}
        </EnhancedGrid>
      </Section>
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        ctaImage={data.page.ctaSection.image}
      />
    </>
  );
};

export default WhoYouAreIndexPage;
